.osano-cm-window{
    z-index:0;
}

.osano-cm-content{
    font-family: poppins;
    font-size: 14px;
    
}

.osano-cm-usage-list{
    margin-top:10px;
}

.osano-cm-link{
    margin-top: 10px;
    font-weight: bold;
}

.osano-cm-list-item:nth-of-type(2){
    display: none;
}

.osano-cm-list-item:nth-of-type(3){
    display: none;
}

.osano-cm-list-item:nth-of-type(4){
    display: none;
}

.osano-cm-buttons{
    font-family: poppins;
    font-size: 13px;
}

.osano-cm-button{
    border-radius:7px;
    padding: 8px;
}

.osano-cm-dialog--type_bar{
    width:320px;
    border-radius: 13px;
    margin: 0 0 25px 25px;
    flex-direction: column;
    gap: 25px;
    
}

.osano-cm-widget--position_right svg path:nth-of-type(1){
    stroke: #D17576 !important;
}

.osano-cm-widget--position_right svg path:nth-of-type(2){
    stroke: #D17576 !important;
}
.osano-cm-widget--position_right svg g {
    fill: #D17576 !important;
}
